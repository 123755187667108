import { commissionTypeItems, DefaultColorPreset, DurationInfo, Periodicity, ProductClient } from "Domain/report";
import { PhoenixBearishTransversalConfiguration } from "Domain/report.phoenixBearish";
import { PhoenixBearishTransversalConfigurationDto } from "Domain/reportDto.phoenixBearish";
import { isNil } from "lodash";
import { DateTime } from "luxon";
import { colorPresetMapper } from "Mappers/colorPresetMapper";
import { DurationInfoMappers } from "Mappers/durationInfoMappers";
import { toDateOnly, toJSDate } from "Utilities/date";
import { PhoenixBearishScheduleLineMappers } from "./phoenixBearishScheduleLineMappers";
import { PhoenixBearishUnderlyingInfoMappers } from "./phoenixBearishUnderlyingInfoMappers";

export function computeDuration(issueDate: Date | undefined, maturityDate: Date | undefined): DurationInfo {
	if (isNil(issueDate) || isNil(maturityDate)) {
		return {
			years: 0,
			months: 0,
			days: 0,
		};
	}
	const start = DateTime.fromJSDate(issueDate).startOf("day");
	const end = DateTime.fromJSDate(maturityDate).startOf("day");
	const res = end.diff(start, ["years", "months", "days"], { conversionAccuracy: "casual" }); // TODO : Check with thomas

	return {
		years: res.years,
		months: res.months,
		days: res.days,
	};
}

function mapEntityToDto(entity: PhoenixBearishTransversalConfiguration): PhoenixBearishTransversalConfigurationDto {
	const dto: PhoenixBearishTransversalConfigurationDto = {
		productDuration: DurationInfoMappers.mapEntityToDto(entity.productDuration),
		productName: entity.productName,
		productGuarantor: entity.productGuarantor ?? "",
		productCotation: entity.productCotation,
		calculationAgent: entity.calculationAgent,
		productIssuer: entity.productIssuer,
		productCurrency: entity.productCurrency,
		minimumInvestment: entity.minimumInvestment,
		isin: entity.isin,
		mtmPublication: entity.mtmPublication,
		productLaw: entity.productLaw,
		hasMarketingPeriod: entity.hasMarketingPeriod,
		marketingStartDate:
			entity.hasMarketingPeriod && entity.marketingPeriod?.startDate
				? toDateOnly(entity.marketingPeriod?.startDate)
				: undefined,
		marketingEndDate:
			entity.hasMarketingPeriod && entity.marketingPeriod?.endDate
				? toDateOnly(entity.marketingPeriod?.endDate)
				: undefined,
		maturityDate: toDateOnly(entity.maturityDate),
		issueDate: toDateOnly(entity.issueDate),
		bidOffer: entity.bidOffer,
		colorPreset: colorPresetMapper.mapEntityToDto(entity.colorPreset),
		doubleValuation: entity.doubleValuation,

		productEligibility: entity.productEligibility,
		pictureId: entity.pictureId,
		commission: entity.commission,
		commissionType: entity.commissionType,
		issuePrice: entity.issuePrice!,
		priceRate: entity.priceRate!,
		productDenomination: entity.productDenomination!,
		ftDate: toDateOnly(entity.ftDate),
		client: entity.client,
		initialValuationDate: toDateOnly(entity.initialValuationDate),

		scheduleLines: entity.scheduleLines.map(PhoenixBearishScheduleLineMappers.mapEntityToDto),
		underlying: PhoenixBearishUnderlyingInfoMappers.mapEntityToDto(entity.underlying),
		autocallBarrier: entity.autocallBarrier,
		couponBarrier: entity.couponBarrier,
		observationPeriodicity: entity.observationPeriodicity,
		observationStartPeriod: entity.observationStartPeriod,
		feesPercent: entity.feesPercent,
		coupon: entity.conditionalCoupon,
		isAutocallProgressive: entity.isAutocallProgressive,
		progressivityStep: entity.isAutocallProgressive ? entity.progressivityStep : undefined,
		progressivityPeriod: entity.isAutocallProgressive ? entity.progressivityPeriod : undefined,
		isGaranteedCoupon: entity.isGaranteedCoupon,
		numberOfUnconditionalCoupons: entity.isGaranteedCoupon ? entity.numberOfUnconditionalCoupons : 0,
		fixedCoupon: entity.isGaranteedCoupon ? entity.unconditionalCoupon : undefined,
		below: entity.below,
		isInfine: entity.isInfine,
	};

	return dto;
}

function mapDtoToEntity(dto: PhoenixBearishTransversalConfigurationDto): PhoenixBearishTransversalConfiguration {
	const maturityDate = toJSDate(dto.maturityDate)!;
	const issueDate = toJSDate(dto.issueDate)!;
	const entity: PhoenixBearishTransversalConfiguration = {
		productName: dto.productName,
		productGuarantor: dto.productGuarantor,
		productCotation: dto.productCotation,
		calculationAgent: dto.calculationAgent,
		productIssuer: dto.productIssuer,
		productCurrency: dto.productCurrency,
		minimumInvestment: dto.minimumInvestment,
		isin: dto.isin,
		mtmPublication: dto.mtmPublication,
		productLaw: dto.productLaw,
		maturityDate: maturityDate,
		issueDate: issueDate,
		productDuration:
			DurationInfoMappers.mapDtoToEntity(dto.productDuration) ?? computeDuration(issueDate, maturityDate),
		bidOffer: dto.bidOffer,
		colorPreset: colorPresetMapper.mapDtoToEntity(dto.colorPreset ?? DefaultColorPreset),
		doubleValuation: dto.doubleValuation,
		productEligibility: dto.productEligibility,
		pictureId: dto.pictureId,
		commission: dto.commission,
		commissionType: dto.commissionType ?? commissionTypeItems.distribués.key,
		issuePrice: dto.issuePrice,
		priceRate: dto.priceRate,
		productDenomination: dto.productDenomination,
		ftDate: dto.ftDate ? toJSDate(dto.ftDate) : undefined,
		client: dto.client as ProductClient,
		initialValuationDate: toJSDate(dto.initialValuationDate, new Date()),
		hasMarketingPeriod: dto.hasMarketingPeriod,
		marketingPeriod: dto.hasMarketingPeriod
			? {
				startDate: toJSDate(dto.marketingStartDate, new Date()),
				endDate: toJSDate(dto.marketingEndDate, new Date()),
			}
			: undefined,

		scheduleLines: dto.scheduleLines.map(PhoenixBearishScheduleLineMappers.mapDtoToEntity),
		totalPeriods: dto.scheduleLines.length,
		underlying: PhoenixBearishUnderlyingInfoMappers.mapDtoToEntity(dto.underlying),
		autocallBarrier: dto.autocallBarrier,
		couponBarrier: dto.couponBarrier,
		observationPeriodicity: dto.observationPeriodicity as Periodicity,
		observationStartPeriod: dto.observationStartPeriod,
		feesPercent: dto.feesPercent,
		conditionalCoupon: dto.coupon,
		isAutocallProgressive: dto.isAutocallProgressive,
		progressivityPeriod: dto.progressivityPeriod,
		progressivityStep: dto.progressivityStep,
		isGaranteedCoupon: dto.isGaranteedCoupon,
		numberOfUnconditionalCoupons: dto.numberOfUnconditionalCoupons,
		unconditionalCoupon: dto.fixedCoupon,
		below: dto.below,
		isInfine: dto.isInfine,
	};

	return entity;
}

export const PhoenixBearishTransversalConfigurationMapper = {
	mapDtoToEntity,
	mapEntityToDto,
};
