import { commissionTypeItems, DefaultColorPreset, ProductCurrency, ProductLaw } from "Domain/report";
import { PhoenixReportConfiguration } from "Domain/report.phoenix";
import { DateTime } from "luxon";

export function createDefaultPhoenixReport(): PhoenixReportConfiguration {
	return {
		transversalConfiguration: {
			productName: "Phoenix",
			productGuarantor: "Société Générale",
			minimumInvestment: 1_000,
			productIssuer: "SG Issuer",
			productCurrency: ProductCurrency.EUR,
			issuePrice: 1,
			priceRate: 0.005,
			productDenomination: 1_000,
			issueDate: DateTime.now().plus({ year: 1 }).toJSDate(),
			maturityDate: DateTime.now().plus({ year: 1 }).toJSDate(),
			productDuration: {
				years: 0,
				months: 0,
				days: 0,
			},

			productCotation: "Bourse de Luxembourg",
			productLaw: ProductLaw.French,
			bidOffer: 0.01,
			commission: 0.01,
			commissionType: commissionTypeItems.distribués.key,
			isin: "",
			calculationAgent: "Société Générale",
			mtmPublication: "",
			colorPreset: DefaultColorPreset,
			initialValuationDate: DateTime.now().toJSDate(),
			hasMarketingPeriod: true,
			marketingPeriod: {
				startDate: DateTime.fromObject({ day: 10, month: 3, year: 2023 }).toJSDate(),
				endDate: DateTime.fromObject({ day: 13, month: 4, year: 2023 }).toJSDate(),
			},
		},
	};
}
