import { productCurrencyItems, productLawItems, productEligibilityItems, periodicityItems, productClientItems, commissionTypeItems } from "Domain/report";
import { TauxFixeReportConfiguration, TauxFixeTransversalConfiguration } from "Domain/report.tauxfixe";
import { FieldConfigurationFromModel } from "components/Shared/FormControls/FieldConfiguration";

export function getTauxFixeFormFieldConfiguration(): FieldConfigurationFromModel<TauxFixeReportConfiguration> {
	const transversalConfig: FieldConfigurationFromModel<TauxFixeTransversalConfiguration> = {
		productName: { type: 'Text', displayLabel: 'Product Name *' },
		productCotation: { type: 'Text', displayLabel: 'Cotation *' },
		calculationAgent: { type: 'Text', displayLabel: 'Calculation *' },
		productIssuer: { type: 'Text', displayLabel: 'Issuer *' },
		productGuarantor: { type: 'Text', displayLabel: 'Guarantor (optional)' },
		productCurrency: { type: 'List', displayLabel: 'Currency *', datasource: productCurrencyItems },
		minimumInvestment: { type: 'Number', displayLabel: "Minimum Investment *" },
		isin: { type: 'Text', displayLabel: 'ISIN Code (optional)' },
		mtmPublication: { type: 'Text', displayLabel: 'Publication Of Liquidative Value *', multiline: false },
		productLaw: { type: 'List', displayLabel: 'Product Law *', datasource: productLawItems },
		marketingPeriod: { type: 'DateRange', displayLabel: 'Marketing Dates (optional)' },
		maturityDate: { type: 'Date', displayLabel: 'Maturity Date *' },
		issueDate: { type: 'Date', displayLabel: 'Issue Date *' },
		productDuration: { type: 'Duration', displayLabel: 'Product Duration *' },
		bidOffer: { type: 'Percent', displayLabel: 'Bid/Offer Spread *' },
		colorPreset: 'Preset *',
		scheduleLines: 'Schedule Lines',
		doubleValuation: { type: 'Text', displayLabel: 'Double Valuation *' },
		fixedCoupon: {
			type: 'Percent',
			displayLabel: 'Fixed Coupons *',
		},
		totalPeriods: {
			type: 'Number',
			displayLabel: 'Total Number Of Periods *',
		},
		productEligibility: { type: 'List', displayLabel: 'Product Eligibility *', datasource: productEligibilityItems },
		commission: { type: 'Percent', displayLabel: 'Commission *' },
		commissionType: { type: "List", displayLabel: "Commission Type *", datasource: commissionTypeItems },
		feesPercent: { type: 'Percent', displayLabel: 'Fees *' },
		issuePrice: { type: 'Percent', displayLabel: 'Issue Price *' },
		productDenomination: { type: 'Number', displayLabel: 'Nominal Value *' },
		priceRate: { type: 'Percent', displayLabel: 'Price Rate *' },
		observationPeriodicity: { type: 'List', displayLabel: 'Observation Periodicity *', datasource: periodicityItems },
		client: { type: 'List', displayLabel: 'Client *', datasource: productClientItems },
		ftDate: { type: 'Date', displayLabel: 'FT Date (optional)' },
		isCallable: { type: 'Checkbox', valueLabel: 'Is this a callable note ?', displayLabel: null },
		callableStartPeriod: { type: 'Number', displayLabel: 'Callable Start Period' },
		isInfine: { type: 'Checkbox', valueLabel: 'Is in fine ?', displayLabel: '' },
		callableNotificationPeriod: { type: 'Number', displayLabel: 'Callable Notification Period' }
	};

	const config: FieldConfigurationFromModel<TauxFixeReportConfiguration> = {
		transversalConfiguration: transversalConfig,
	};
	return config;
}
