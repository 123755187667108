import * as yup from 'yup';
import { ProductCurrency, productCurrencyItems, DurationInfo, ProductLaw, productLawItems, ColorPreset, ProductEligibility, productEligibilityItems, Guid, Periodicity, ProductClient } from 'Domain/report';
import { TarnTransversalConfiguration, TarnUnderlyingInfo, TarnScheduleLine, CouponDisplayMode, couponDisplayModeItems, TarnReportConfiguration } from 'Domain/report.tarn';

export const transversalConfigurationSchema = (): yup.Schema<TarnTransversalConfiguration> => {
	const configSchema = yup.object({
		productName: yup.string().defined().min(1),
		productIssuer: yup.string().defined().min(1),
		minimumInvestment: yup.number().defined(),
		productCurrency: yup
			.mixed<ProductCurrency>()
			.oneOf(Object.values(productCurrencyItems).map((e) => e.key))
			.defined(),
		issuePrice: yup.number().defined(),
		priceRate: yup.number().defined(),
		productDenomination: yup.number().defined(),
		productGuarantor: yup.string().defined().min(1),
		issueDate: yup.date().defined(),
		maturityDate: yup.date().defined(),
		productDuration: yup.mixed<DurationInfo>().defined(),
		firstUnderlying: yup.mixed<TarnUnderlyingInfo>().defined(),
		secondUnderlying: yup.mixed<TarnUnderlyingInfo>().defined(),
		autocallBarrier: yup.number().defined(),
		feesPercent: yup.number().defined(),
		fixedCouponPeriods: yup.number().defined(),
		totalPeriods: yup.number().defined(),
		productCotation: yup.string().defined().min(1),
		calculationAgent: yup.string().defined().min(1),
		isin: yup.string().optional(),
		capitalPaymentBusinessDays: yup.string().defined(),
		mtmPublication: yup.string().required().min(1),
		productLaw: yup
			.mixed<ProductLaw>()
			.oneOf(Object.values(productLawItems).map((e) => e.key))
			.defined(),
		marketingPeriod: yup
			.object({
				startDate: yup.date().required(),
				endDate: yup.date().required(),
			})
			.when('productEligibility', { is: 'CompteTitreAssuranceVieCapitalisation', then: (s) => s.required() })
			.when('productEligibility', { is: 'CompteTitre', then: (s) => s.required() }),
		rateCurrency: yup.string().defined(),
		bidOffer: yup.number().defined(),
		colorPreset: yup.mixed<ColorPreset>().defined(),
		scheduleLines: yup
			.array<TarnScheduleLine>()
			.of(
				yup.object({
					paymentDate: yup.date().required(),
					periodNumber: yup.number().required(),
					isAutocallable: yup.boolean().required(),
					autocallBarrier: yup.number().required(),
					isFloatingCouponApplicable: yup.boolean().required(),
					isFloatingCouponLeveraged: yup.boolean().required(),
					isFixedCouponApplicable: yup.boolean().required(),
					fixedCoupon: yup.number().required(),
					leverage: yup.number().required(),
				})
			)
			.defined(),
		doubleValuation: yup.string().optional(),
		fixedCoupon: yup.number().defined(),
		productEligibility: yup
			.mixed<ProductEligibility>()
			.required()
			.oneOf(Object.values(productEligibilityItems).map((e) => e.key)),
		couponDisplayMode: yup
			.mixed<CouponDisplayMode>()
			.required()
			.oneOf(Object.values(couponDisplayModeItems).map((e) => e.key)),
		pictureId: yup.mixed<Guid>().defined(),
		commission: yup.number().defined(),
		commissionType: yup.string().defined(),
		observationPeriodicity: yup.mixed<Periodicity>().defined(),
		client: yup.mixed<ProductClient>().defined(),
		hasLeverage: yup.bool().defined(),
		leverageAmount: yup.number().when('hasLeverage', { is: true, then: (s) => s.required().min(1) }),
		hasZc: yup.bool().defined(),
		bonusCoupon: yup.number().optional(),
		bonusPeriod: yup
			.object({
				startDate: yup.date().required(),
				endDate: yup.date().required(),
			})
			.when('bonusCoupon', { is: (bonus: number) => bonus && bonus > 0, then: (s) => s.required() }),
	});
	return configSchema;
};

export function getTarnValidationSchema(): yup.Schema<TarnReportConfiguration> {
	const schema: yup.Schema<TarnReportConfiguration> = yup.object({
		transversalConfiguration: transversalConfigurationSchema(),
	});
	return schema;
}
