
import { isNil } from 'lodash';
import { DateTime } from 'luxon';
import { Periodicity, DurationInfo, DefaultColorPreset, ProductClient, commissionTypeItems } from 'Domain/report';
import { TarnTransversalConfiguration, CouponDisplayMode } from 'Domain/report.tarn';
import { TarnTransversalConfigurationDto } from 'Domain/reportDto.tarn';
import { toDateOnly, toJSDate } from 'Utilities/date';
import { colorPresetMapper } from '../colorPresetMapper';
import { DurationInfoMappers } from '../durationInfoMappers';
import { TarnUnderlyingInfoMappers } from './TarnUnderlyingInfoMappers';
import { tarnScheduleLineMappers } from './tarnScheduleLineMappers';

function mapEntityToDto(entity: TarnTransversalConfiguration): TarnTransversalConfigurationDto {
	const dto: TarnTransversalConfigurationDto = {
		productDuration: DurationInfoMappers.mapEntityToDto(entity.productDuration),
		productName: entity.productName,
		productPeriodicity: { period: Periodicity.Years, duration: entity.maturityDate!.getFullYear() - entity.issueDate!.getFullYear() },
		productGuarantor: entity.productGuarantor,
		productCotation: entity.productCotation,
		calculationAgent: entity.calculationAgent,
		productIssuer: entity.productIssuer,
		productCurrency: entity.productCurrency,
		minimumInvestment: entity.minimumInvestment,
		isin: entity.isin,
		mtmPublication: entity.mtmPublication,
		productLaw: entity.productLaw,
		firstUnderlying: TarnUnderlyingInfoMappers.mapEntityToDto(entity.firstUnderlying),
		secondUnderlying: TarnUnderlyingInfoMappers.mapEntityToDto(entity.secondUnderlying),
		hasMarketingPeriod: entity.marketingPeriod?.startDate !== undefined && entity.marketingPeriod?.endDate !== undefined,
		marketingStartDate: entity.marketingPeriod?.startDate ? toDateOnly(entity.marketingPeriod?.startDate) : undefined,
		marketingEndDate: entity.marketingPeriod?.endDate ? toDateOnly(entity.marketingPeriod?.endDate) : undefined,
		maturityDate: toDateOnly(entity.maturityDate),
		issueDate: toDateOnly(entity.issueDate),
		rateCurrency: entity.rateCurrency,
		bidOffer: entity.bidOffer,
		capitalPaymentBusinessDays: parseInt(entity.capitalPaymentBusinessDays!),
		autocallBarrier: entity.autocallBarrier,
		colorPreset: colorPresetMapper.mapEntityToDto(entity.colorPreset),
		scheduleLines: entity.scheduleLines.map(tarnScheduleLineMappers.mapEntityToDto),
		doubleValuation: entity.doubleValuation,
		fixedCoupon: entity.fixedCoupon,
		productEligibility: entity.productEligibility,
		pictureId: entity.pictureId,
		commission: entity.commission,
		commissionType: entity.commissionType ?? commissionTypeItems.distribués.key,
		feesPercent: entity.feesPercent,
		issuePrice: entity.issuePrice!,
		priceRate: entity.priceRate!,
		productDenomination: entity.productDenomination!,
		ftDate: toDateOnly(entity.ftDate),
		observationPeriodicity: entity.observationPeriodicity,
		client: entity.client,
		hasLeverage: entity.hasLeverage,
		leverageAmount: entity.leverageAmount,
		hasZc: entity.hasZc,
		hasBonus: entity.bonusCoupon !== undefined,
		bonusCoupon: entity.bonusCoupon,
		bonusEndDate: entity.bonusPeriod?.endDate ? toDateOnly(entity.bonusPeriod?.endDate) : undefined,
		bonusStartDate: entity.bonusPeriod?.startDate ? toDateOnly(entity.bonusPeriod?.startDate) : undefined,
	};

	return dto;
}

export function computeDuration(issueDate: Date | undefined, maturityDate: Date | undefined): DurationInfo {
	if (isNil(issueDate) || isNil(maturityDate)) {
		return {
			years: 0,
			months: 0,
			days: 0,
		};
	}
	const start = DateTime.fromJSDate(issueDate).startOf('day');
	const end = DateTime.fromJSDate(maturityDate).startOf('day');
	const res = end.diff(start, ['years', 'months', 'days'], { conversionAccuracy: 'casual' }); // TODO : Check with thomas

	return {
		years: res.years,
		months: res.months,
		days: res.days,
	};
}

function mapDtoToEntity(dto: TarnTransversalConfigurationDto): TarnTransversalConfiguration {
	const maturityDate = toJSDate(dto.maturityDate)!;
	const issueDate = toJSDate(dto.issueDate)!;
	const entity: TarnTransversalConfiguration = {
		productName: dto.productName,
		productGuarantor: dto.productGuarantor,
		productCotation: dto.productCotation,
		calculationAgent: dto.calculationAgent,
		productIssuer: dto.productIssuer,
		productCurrency: dto.productCurrency,
		minimumInvestment: dto.minimumInvestment,
		isin: dto.isin,
		mtmPublication: dto.mtmPublication,
		productLaw: dto.productLaw,
		firstUnderlying: TarnUnderlyingInfoMappers.mapDtoToEntity(dto.firstUnderlying),
		secondUnderlying: TarnUnderlyingInfoMappers.mapDtoToEntity(dto.secondUnderlying),
		marketingPeriod: dto.hasMarketingPeriod
			? {
				startDate: toJSDate(dto.marketingStartDate, new Date()),
				endDate: toJSDate(dto.marketingEndDate, new Date()),
			}
			: undefined,
		maturityDate: maturityDate,
		issueDate: issueDate,
		productDuration: DurationInfoMappers.mapDtoToEntity(dto.productDuration) ?? computeDuration(issueDate, maturityDate),
		rateCurrency: dto.rateCurrency,
		bidOffer: dto.bidOffer,
		capitalPaymentBusinessDays: dto.capitalPaymentBusinessDays ? dto.capitalPaymentBusinessDays.toString() : undefined,
		autocallBarrier: dto.autocallBarrier,
		colorPreset: colorPresetMapper.mapDtoToEntity(dto.colorPreset ?? DefaultColorPreset),
		scheduleLines: dto.scheduleLines.map(tarnScheduleLineMappers.mapDtoToEntity),
		doubleValuation: dto.doubleValuation,
		fixedCoupon: dto.fixedCoupon,
		productEligibility: dto.productEligibility,
		pictureId: dto.pictureId,
		commission: dto.commission,
		commissionType: dto.commissionType,
		feesPercent: dto.feesPercent,
		issuePrice: dto.issuePrice,
		priceRate: dto.priceRate,
		productDenomination: dto.productDenomination,
		ftDate: dto.ftDate ? toJSDate(dto.ftDate) : undefined,
		observationPeriodicity: dto.observationPeriodicity as Periodicity,
		client: dto.client as ProductClient,
		hasLeverage: dto.hasLeverage,
		leverageAmount: dto.leverageAmount,
		hasZc: dto.hasZc,
		bonusCoupon: dto.bonusCoupon,
		bonusPeriod:
			dto.bonusStartDate && dto.bonusEndDate
				? {
					startDate: toJSDate(dto.bonusStartDate, new Date()),
					endDate: toJSDate(dto.bonusEndDate, new Date()),
				}
				: undefined,
		couponDisplayMode: CouponDisplayMode.TotalCoupons,
	};

	return entity;
}

export const tarnTransversalConfigurationMapper = {
	mapDtoToEntity,
	mapEntityToDto,
};
