import { ReportFormSection } from "../ReportFormSection";
import TauxFixeFormControl from "./Form/TauxFixeFormControl";
import GenerateTauxFixeBrochureButton from "./GenerateTauxFixeBrochureButton";
import { TauxFixeScheduleLinesTable } from "./Form/TauxFixeScheduleLinesTable";
import { LiquidityValuesHelper } from "../Athena/AthenaForm";
import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { LoadingState } from "Models/LoadingState";
import { ColorSelector } from "components/Shared/ColorSelector/ColorSelector";
import { PictureSelector } from "components/Shared/PictureSelector/PictureSelector";
import { cssHelper } from "components/Shared/cssHelper";
import TauxFixeSlice, { editValueCreatorHelper } from "state/Reports/TauxFixe/tauxFixe.state";
import { useTauxFixeValidationStateForField } from "state/Reports/TauxFixe/useTauxFixeValidationStateForField";
import { BrochureGeneratorAppState } from "Domain/report";
import { useAnalyticsMonitoring } from "@analytics-web-core/core";

export const TauxFixeReport: React.FC = () => {
	const TauxFixeState = useSelector((a: BrochureGeneratorAppState) => a.tauxFixe);
	const pictureValidationState = useTauxFixeValidationStateForField("transversalConfiguration", "pictureId");
	const colorValidationState = useTauxFixeValidationStateForField("transversalConfiguration", "colorPreset");
	const dispatcher = useDispatch();
	const analyticsMonitoring = useAnalyticsMonitoring();
	const { brochureLoadingState } = TauxFixeState;
	const isLoading = brochureLoadingState === LoadingState.Pending;
	const currentReport = TauxFixeState.currentReport;

	const transversalConfiguration = currentReport.transversalConfiguration;
	const isScheduleHidden = transversalConfiguration.isInfine && !transversalConfiguration.isCallable;

	const onReset = () => {
		dispatcher(TauxFixeSlice.actions.resetReport());
		analyticsMonitoring.functional("Taux Fixe", "Reset form");
	};

	return (
		<div className="d-flex flex-column h-100 overflow-hidden">
			<div className="p-3 d-flex align-items-center shadow border-bottom">
				<div className="display-4 flex-fill">Taux Fixe</div>
			</div>
			<div className="flex-fill position-relative bg-lvl3">
				{isLoading && <div className="prime-overlay"></div>}
				<div className="position-absolute w-100 h-100 px-3 py-4" style={{ overflowY: "auto" }}>
					<div className="row mb-4">
						<div className="col">
							<ReportFormSection title={"Product Information"} containerClassName="mb-1">
								<div className="row">
									<div className="col-6">
										<TauxFixeFormControl contextKey="transversalConfiguration" field={"productName"} />
										<TauxFixeFormControl contextKey="transversalConfiguration" field={"productIssuer"} />
										<TauxFixeFormControl contextKey="transversalConfiguration" field={"productGuarantor"} />
										<TauxFixeFormControl contextKey="transversalConfiguration" field={"productCurrency"} />
										<TauxFixeFormControl contextKey="transversalConfiguration" field={"productDenomination"} />
									</div>
									<div className="col-6">
										<div className="row">
											<TauxFixeFormControl contextKey="transversalConfiguration" field={"issuePrice"} />
											<TauxFixeFormControl contextKey="transversalConfiguration" field={"priceRate"} />
										</div>
										<TauxFixeFormControl contextKey="transversalConfiguration" field={"minimumInvestment"} />
										<TauxFixeFormControl contextKey="transversalConfiguration" field={"issueDate"} />
										<TauxFixeFormControl contextKey="transversalConfiguration" field={"maturityDate"} />
										<TauxFixeFormControl contextKey="transversalConfiguration" field={"productDuration"} />
									</div>
								</div>
							</ReportFormSection>
						</div>
						<div className="col">
							<div className={`card card-bordered bg-lvl2 h-100 mb-4`}>
								<div className="card-body pb-0">
									<div className="d-flex flex-column h-100">
										<div>
											<TauxFixeFormControl contextKey="transversalConfiguration" field={"isInfine"} />
										</div>
										<div className="flex-fill">
											{!isScheduleHidden && (
												<div className="vstack h-100 pb-3">
													<div>
														<div className="form-label">Schedule Lines</div>
													</div>
													<div className="flex-fill">
														<TauxFixeScheduleLinesTable />
													</div>
												</div>
											)}
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="row">
						<div className="col">
							<ReportFormSection title={"PayOff Information"} containerClassName="mb-4">
								<div className="row mb-4">
									<TauxFixeFormControl contextKey="transversalConfiguration" field={"fixedCoupon"} size={3} />
									<TauxFixeFormControl contextKey="transversalConfiguration" field={"totalPeriods"} size={3} />
									<TauxFixeFormControl
										contextKey="transversalConfiguration"
										field={"observationPeriodicity"}
										size={3}
									/>
									<TauxFixeFormControl contextKey="transversalConfiguration" field={"feesPercent"} size={3} />
								</div>
								<div className="row mb-4">
									<TauxFixeFormControl contextKey="transversalConfiguration" field={"isCallable"} size={4} />
									<TauxFixeFormControl
										contextKey="transversalConfiguration"
										field={"callableStartPeriod"}
										size={4}
										fieldConfigOverrides={{ hidden: !transversalConfiguration.isCallable }}
									/>
									<TauxFixeFormControl
										contextKey="transversalConfiguration"
										field={"callableNotificationPeriod"}
										size={4}
										fieldConfigOverrides={{ hidden: !transversalConfiguration.isCallable }}
									/>
								</div>
							</ReportFormSection>
						</div>
						<div className="col">
							<ReportFormSection title={"Deal Information"} containerClassName="mb-4">
								<div className="row">
									<div className="col-4">
										<TauxFixeFormControl contextKey="transversalConfiguration" field={"productCotation"} />
									</div>
									<div className="col-4">
										<TauxFixeFormControl contextKey="transversalConfiguration" field={"productLaw"} />
									</div>
									<div className="col-4">
										<TauxFixeFormControl contextKey="transversalConfiguration" field={"bidOffer"} />
									</div>
								</div>
								<div className="row">
									<div className="col-4">
										<TauxFixeFormControl contextKey="transversalConfiguration" field={"productEligibility"} />
									</div>
									<div className="col-4">
										<TauxFixeFormControl contextKey="transversalConfiguration" field={"commission"} />
									</div>
									<div className="col-4">
										<TauxFixeFormControl contextKey="transversalConfiguration" field={"commissionType"} />
									</div>
								</div>
								<div className="row">
									<div className="col-8">
										<TauxFixeFormControl contextKey="transversalConfiguration" field={"marketingPeriod"} />
									</div>
									<div className="col-4">
										<TauxFixeFormControl contextKey="transversalConfiguration" field={"doubleValuation"} />
									</div>
								</div>
								<div className="row">
									<TauxFixeFormControl contextKey="transversalConfiguration" field={"mtmPublication"} />
									<div className={cssHelper.colSizeClassNames(4, "mb-3")}>
										<div className="form-label">&nbsp;</div>
										<LiquidityValuesHelper
											onSelect={(val) =>
												dispatcher(editValueCreatorHelper("transversalConfiguration", "mtmPublication", val))
											}
										/>
									</div>
								</div>
								<div className="row">
									<div className="col-4">
										<TauxFixeFormControl contextKey="transversalConfiguration" field={"isin"} />
									</div>
									<div className="col-4">
										<TauxFixeFormControl contextKey="transversalConfiguration" field={"calculationAgent"} />
									</div>
									<div className="col-4">
										<TauxFixeFormControl contextKey="transversalConfiguration" field={"client"} />
									</div>
								</div>
								<div className="row">
									<TauxFixeFormControl contextKey="transversalConfiguration" field={"ftDate"} size={4} />
								</div>
								<div className="row">
									<div className="col-6">
										<div className="form-label">Client Theme *</div>
										<ColorSelector
											errorMessage={colorValidationState}
											headerTitle="Client Theme"
											selectedPreset={transversalConfiguration.colorPreset}
											onChange={(preset) => {
												dispatcher(editValueCreatorHelper("transversalConfiguration", "colorPreset" as any, preset));
											}}
										/>
									</div>
									<div className="col-6">
										<div className="form-label">Cover Picture *</div>
										<PictureSelector
											errorMessage={pictureValidationState}
											selectedPictureId={transversalConfiguration.pictureId}
											onChange={(id) => {
												dispatcher(editValueCreatorHelper("transversalConfiguration", "pictureId" as any, id));
											}}
										/>
									</div>
								</div>
							</ReportFormSection>
						</div>
					</div>
				</div>
			</div>

			<div className="p-3 shadow border-top">
				<div className="container-fluid p-0">
					<div className="row">
						<div className="col-auto ms-auto">
							<button className="btn btn-xl btn-outline-secondary" disabled={isLoading} onClick={onReset}>
								Reset
							</button>
						</div>
						<div className="col-auto">
							<GenerateTauxFixeBrochureButton />
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};
