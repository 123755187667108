import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { LoadingState } from "Models/LoadingState";
import { ColorSelector } from "components/Shared/ColorSelector/ColorSelector";
import { PictureSelector } from "components/Shared/PictureSelector/PictureSelector";
import { cssHelper } from "components/Shared/cssHelper";
import { BrochureGeneratorAppState } from "Domain/report";
import { useTarnValidationStateForField } from "state/Reports/Tarn/useTarnValidationStateForField";
import { LiquidityValuesHelper } from "../Athena/AthenaForm";
import { ReportFormSection } from "../ReportFormSection";
import TarnFormControl from "./Form/TarnFormControl";
import { TarnScheduleLinesTable } from "./Form/TarnScheduleLinesTable";
import TarnUnderlyingSelector from "./Form/TarnUnderlyingSelector";
import GenerateTarnBrochureButton from "./GenerateTarnBrochureButton";
import TarnSlice, { editValueCreatorHelper } from "state/Reports/Tarn/tarn.state";
import { useAnalyticsMonitoring } from "@analytics-web-core/core";

export const TarnReport: React.FC = () => {
	const tarnState = useSelector((a: BrochureGeneratorAppState) => a.tarn);
	const dispatcher = useDispatch();
	const analyticsMonitoring = useAnalyticsMonitoring();
	const pictureValidationState = useTarnValidationStateForField("transversalConfiguration", "pictureId");
	const colorValidationState = useTarnValidationStateForField("transversalConfiguration", "colorPreset");

	const { brochureLoadingState } = tarnState;
	const isLoading = brochureLoadingState === LoadingState.Pending;
	const currentReport = tarnState.currentReport;
	const onReset = () => {
		dispatcher(TarnSlice.actions.resetReport());
		analyticsMonitoring.functional("Tarn", "Reset form");
	};
	return (
		<div className="d-flex flex-column h-100  overflow-hidden">
			<div className="p-3 d-flex align-items-center shadow border-bottom">
				<div className="display-4 flex-fill">
					Tarn {tarnState.currentReport.transversalConfiguration.hasZc ? "ZC" : ""}
				</div>
			</div>
			<div className="flex-fill position-relative bg-lvl3">
				{isLoading && <div className="prime-overlay"></div>}
				<div className="position-absolute w-100 h-100 px-3 py-4" style={{ overflowY: "auto" }}>
					<div className="row mb-4">
						<div className="col">
							<ReportFormSection title={"Product Information"} containerClassName="mb-1">
								<div className="row">
									<div className="col-6">
										<TarnFormControl contextKey="transversalConfiguration" field={"productName"} />
										<TarnFormControl contextKey="transversalConfiguration" field={"productIssuer"} />
										<TarnFormControl contextKey="transversalConfiguration" field={"productGuarantor"} />
										<TarnFormControl contextKey="transversalConfiguration" field={"productCurrency"} />
										<TarnFormControl contextKey="transversalConfiguration" field={"productDenomination"} />
									</div>
									<div className="col-6">
										<div className="row">
											<TarnFormControl contextKey="transversalConfiguration" field={"issuePrice"} />
											<TarnFormControl contextKey="transversalConfiguration" field={"priceRate"} />
										</div>
										<TarnFormControl contextKey="transversalConfiguration" field={"minimumInvestment"} />
										<TarnFormControl contextKey="transversalConfiguration" field={"issueDate"} />
										<TarnFormControl contextKey="transversalConfiguration" field={"maturityDate"} />
										<TarnFormControl contextKey="transversalConfiguration" field={"productDuration"} />
									</div>
								</div>
							</ReportFormSection>
						</div>
						<div className="col">
							<div className={`card card-bordered bg-lvl2 h-100 mb-4`}>
								<div className="card-body pb-0">
									<div className="d-flex flex-column h-100">
										<div>
											<div className="form-label">Schedule Lines</div>
										</div>
										<div className="flex-fill mb-3">
											<TarnScheduleLinesTable />
										</div>
										<div>
											<div className="col-6">
												<TarnFormControl contextKey="transversalConfiguration" field={"capitalPaymentBusinessDays"} />
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="row">
						<div className="col">
							<ReportFormSection title={"PayOff Information"} containerClassName="mb-4">
								{/* <div className="row">
									<TarnFormControl contextKey="transversalConfiguration" field={'couponDisplayMode'} />
								</div> */}
								<div className="row">
									<TarnFormControl contextKey="transversalConfiguration" field={"autocallBarrier"} size={4} />
									<TarnFormControl contextKey="transversalConfiguration" field={"fixedCouponPeriods"} size={4} />
									<TarnFormControl contextKey="transversalConfiguration" field={"totalPeriods"} size={4} />
									<TarnFormControl contextKey="transversalConfiguration" field={"observationPeriodicity"} size={4} />
									<TarnFormControl contextKey="transversalConfiguration" field={"fixedCoupon"} size={4} />
									<TarnFormControl contextKey="transversalConfiguration" field={"feesPercent"} size={4} />
									<TarnFormControl contextKey="transversalConfiguration" field={"hasLeverage"} size={4} />
									<TarnFormControl
										contextKey="transversalConfiguration"
										field={"leverageAmount"}
										size={8}
										fieldConfigOverrides={{ disabled: !currentReport.transversalConfiguration.hasLeverage }}
									/>
								</div>

								{/* {tarnState.currentReport.transversalConfiguration.hasZc && (
									<div className="row mb-4">
											<TarnFormControl contextKey="transversalConfiguration" field={'bonusCoupon'} size={4} />
											<TarnFormControl contextKey="transversalConfiguration" field={'bonusPeriod'} size={8} />
									</div>
								)} */}
								<div className="row mb-4">
									<TarnFormControl contextKey="transversalConfiguration" field={"hasZc"} size={4} />
									<TarnFormControl
										contextKey="transversalConfiguration"
										field={"bonusCoupon"}
										size={4}
										fieldConfigOverrides={{ disabled: !currentReport.transversalConfiguration.hasZc }}
									/>
									<TarnFormControl
										contextKey="transversalConfiguration"
										field={"bonusPeriod"}
										size={4}
										fieldConfigOverrides={{ disabled: !currentReport.transversalConfiguration.hasZc }}
									/>
								</div>
								<div className="row">
									<div className="col-12">
										<TarnUnderlyingSelector />
									</div>
								</div>
							</ReportFormSection>
						</div>
						<div className="col">
							<ReportFormSection title={"Deal Information"} containerClassName="mb-4">
								<div className="row">
									<div className="col-4">
										<TarnFormControl contextKey="transversalConfiguration" field={"productCotation"} />
									</div>
									<div className="col-4">
										<TarnFormControl contextKey="transversalConfiguration" field={"productLaw"} />
									</div>
									<div className="col-4">
										<TarnFormControl contextKey="transversalConfiguration" field={"bidOffer"} />
									</div>
								</div>
								<div className="row">
									<div className="col-4">
										<TarnFormControl contextKey="transversalConfiguration" field={"productEligibility"} />
									</div>
									<div className="col-4">
										<TarnFormControl contextKey="transversalConfiguration" field={"commission"} />
									</div>
									<div className="col-4">
										<TarnFormControl contextKey="transversalConfiguration" field={"commissionType"} />
									</div>
								</div>
								<div className="row">
									<div className="col-8">
										<TarnFormControl contextKey="transversalConfiguration" field={"marketingPeriod"} />
									</div>
									<div className="col-4">
										<TarnFormControl contextKey="transversalConfiguration" field={"doubleValuation"} />
									</div>
								</div>
								<div className="row">
									<TarnFormControl contextKey="transversalConfiguration" field={"mtmPublication"} />
									<div className={cssHelper.colSizeClassNames(4, "mb-3")}>
										<div className="form-label">&nbsp;</div>
										<LiquidityValuesHelper
											onSelect={(val) =>
												dispatcher(editValueCreatorHelper("transversalConfiguration", "mtmPublication", val))
											}
										/>
									</div>
								</div>
								<div className="row">
									<div className="col-4">
										<TarnFormControl contextKey="transversalConfiguration" field={"isin"} />
									</div>
									<div className="col-4">
										<TarnFormControl contextKey="transversalConfiguration" field={"calculationAgent"} />
									</div>
									<div className="col-4">
										<TarnFormControl contextKey="transversalConfiguration" field={"client"} />
									</div>
								</div>
								<div className="row">
									<div className="col-12">
										<TarnFormControl contextKey="transversalConfiguration" field={"ftDate"} size={4} />
									</div>
								</div>
								<div className="row">
									<div className="col-6">
										<div className="form-label">Client Theme *</div>
										<ColorSelector
											errorMessage={colorValidationState}
											headerTitle="Client Theme"
											selectedPreset={currentReport.transversalConfiguration.colorPreset}
											onChange={(preset) => {
												dispatcher(editValueCreatorHelper("transversalConfiguration", "colorPreset" as any, preset));
											}}
										/>
									</div>
									<div className="col-6">
										<div className="form-label">Cover Picture *</div>
										<PictureSelector
											errorMessage={pictureValidationState}
											selectedPictureId={currentReport.transversalConfiguration.pictureId}
											onChange={(id) => {
												dispatcher(editValueCreatorHelper("transversalConfiguration", "pictureId" as any, id));
											}}
										/>
									</div>
								</div>
							</ReportFormSection>
						</div>
					</div>
				</div>
			</div>

			<div className="p-3 shadow border-top">
				<div className="container-fluid p-0">
					<div className="row">
						<div className="col-auto ms-auto">
							<button className="btn btn-xl btn-outline-secondary" disabled={isLoading} onClick={onReset}>
								Reset
							</button>
						</div>
						<div className="col-auto">
							<GenerateTarnBrochureButton />
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};
