import {
	BelowChoices,
	commissionTypeItems,
	DefaultColorPreset,
	Periodicity,
	ProductCurrency,
	ProductLaw,
} from "Domain/report";
import { PhoenixBearishReportConfiguration, phoenixBearishUnderlyings } from "Domain/report.phoenixBearish";
import { DateTime } from "luxon";

export function createDefaultPhoenixBearishReport(): PhoenixBearishReportConfiguration {
	const defaultPeriodUnit: Periodicity = Periodicity.Months;
	const baseCoupon = 0.0025;
	const observationStart = 4;

	return {
		transversalConfiguration: {
			productName: "Phoenix Bearish",
			productGuarantor: "Société Générale",
			minimumInvestment: 1_000,
			productIssuer: "SG Issuer",
			productCurrency: ProductCurrency.EUR,
			issuePrice: 1,
			priceRate: 0,
			productDenomination: 1_000,
			issueDate: DateTime.now().toJSDate(),
			maturityDate: DateTime.now().plus({ year: 1 }).toJSDate(),
			productDuration: {
				years: 0,
				months: 0,
				days: 0,
			},

			productCotation: "Bourse de Luxembourg",
			productLaw: ProductLaw.French,
			bidOffer: 0.01,
			commission: 0.01,
			commissionType: commissionTypeItems.distribués.key,
			isin: "",
			calculationAgent: "Société Générale",
			mtmPublication: "",
			colorPreset: DefaultColorPreset,
			initialValuationDate: DateTime.now().toJSDate(),
			hasMarketingPeriod: true,
			marketingPeriod: {
				startDate: DateTime.now().toJSDate(),
				endDate: DateTime.now().plus({ month: 1 }).toJSDate(),
			},

			scheduleLines: [],
			underlying: phoenixBearishUnderlyings.find((u) => u.maturityYears === 10)!,
			autocallBarrier: 0.01,
			observationPeriodicity: defaultPeriodUnit,
			observationStartPeriod: observationStart,
			totalPeriods: 0,
			couponBarrier: 0.005,
			feesPercent: 0.01,
			conditionalCoupon: baseCoupon,
			isAutocallProgressive: false,
			progressivityPeriod: undefined,
			progressivityStep: undefined,
			isGaranteedCoupon: false,
			below: BelowChoices.LessThanOrEqualTo,
			isInfine: false,
			numberOfUnconditionalCoupons: 0,
		},
	};
}
