import * as yup from 'yup';
import { ProductCurrency, productCurrencyItems, DurationInfo, ProductLaw, productLawItems, ColorPreset, ProductEligibility, productEligibilityItems, Guid, Periodicity, ProductClient } from 'Domain/report';
import { TauxFixeTransversalConfiguration, TauxFixeScheduleLine, TauxFixeReportConfiguration } from 'Domain/report.tauxfixe';

export const transversalConfigurationSchema = (): yup.Schema<TauxFixeTransversalConfiguration> => {
	const configSchema = yup.object({
		productName: yup.string().defined().min(1),
		productIssuer: yup.string().defined().min(1),
		minimumInvestment: yup.number().defined(),
		productCurrency: yup
			.mixed<ProductCurrency>()
			.oneOf(Object.values(productCurrencyItems).map((e) => e.key))
			.defined(),
		issuePrice: yup.number().defined(),
		priceRate: yup.number().defined(),
		productDenomination: yup.number().defined(),
		productGuarantor: yup.string().optional(),
		issueDate: yup.date().defined(),
		maturityDate: yup.date().defined(),
		productDuration: yup.mixed<DurationInfo>().defined(),
		feesPercent: yup.number().defined(),
		totalPeriods: yup.number().defined(),
		productCotation: yup.string().defined().min(1),
		calculationAgent: yup.string().defined().min(1),
		isin: yup.string().optional(),
		mtmPublication: yup.string().required().min(1),
		productLaw: yup
			.mixed<ProductLaw>()
			.oneOf(Object.values(productLawItems).map((e) => e.key))
			.defined(),
		marketingPeriod: yup
			.object({
				startDate: yup.date().required(),
				endDate: yup.date().required(),
			})
			.when('productEligibility', { is: 'CompteTitreAssuranceVieCapitalisation', then: (s) => s.required() })
			.when('productEligibility', { is: 'CompteTitre', then: (s) => s.required() }),
		bidOffer: yup.number().defined(),
		colorPreset: yup.mixed<ColorPreset>().defined(),
		scheduleLines: yup.array<TauxFixeScheduleLine>().of(yup.object({
			paymentDate: yup.date().required(),
			periodNumber: yup.number().required(),
			fixedCoupon: yup.number().optional(),
		})).defined(),
		doubleValuation: yup.string().defined(),
		fixedCoupon: yup.number().defined(),
		productEligibility: yup
			.mixed<ProductEligibility>()
			.required()
			.oneOf(Object.values(productEligibilityItems).map((e) => e.key)),
		pictureId: yup.mixed<Guid>().defined(),
		commission: yup.number().defined(),
		commissionType: yup.string().defined(),
		observationPeriodicity: yup.mixed<Periodicity>().defined(),
		client: yup.mixed<ProductClient>().defined(),
		isCallable: yup.bool().defined(),
		callableStartPeriod: yup.number().when('isCallable', { is: true, then: (s) => s.required() }),
		isInfine: yup.bool().defined(),
		callableNotificationPeriod: yup.number().when('isCallable', { is: true, then: (s) => s.required() }),
	});
	return configSchema;
};

export function getTauxFixeValidationSchema(): yup.Schema<TauxFixeReportConfiguration> {
	const schema: yup.Schema<TauxFixeReportConfiguration> = yup.object({
		transversalConfiguration: transversalConfigurationSchema(),
	});
	return schema;
}
