import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export type DisclaimerItem = {
	content: string;
	description: string;
	disclaimerId: string;
	id: number | undefined;
};

export function createNewUnderlyingItem(): UnderlyingItem {
	return {
		id: undefined,
		underlyingType: 'Stock',
		ticker: '',
		isin: '',
		name: '',
		commercialName: '',
		globalDescription: '',
		lastStrike: 0.0,
		legend: '',
		launch: '',
		longName: '',
		globalDisclaimer: '',
		adjustedName: '',
		decrement: '',
		decrementComparison: '',
		licensorWebsite: '',
		externalSource: '',
		creation: '',
		secteur: '',
		notationSNP: '',
		notationMoodys: '',
		chiffreDaffaires: '',
		resultatNet: '',
		endettementNet: '',
		capitauxPropres: '',
		siteInternet: '',
		siteCotation: '',
		inconvenients: '',
		risks: '',
		fundComparison: '',
	};
}

export type UnderlyingItemType = "Fund" | "Stock" | "Indice";
export type UnderlyingItem = {
	id: number | undefined;
	underlyingType: UnderlyingItemType;
	ticker: string;
	isin: string;
	name: string;
	commercialName: string;
	globalDescription: string;
	lastStrike: number | undefined;
	// Indice
	legend: string;
	launch: string;
	longName: string;
	globalDisclaimer: string;
	adjustedName: string;
	decrement: string;
	decrementComparison: string;
	licensorWebsite: string;
	externalSource: string;
	// stock
	creation: string;
	secteur: string;
	notationSNP: string;
	notationMoodys: string;
	chiffreDaffaires: string;
	resultatNet: string;
	endettementNet: string;
	capitauxPropres: string;
	siteInternet: string;
	siteCotation: string;
	// fonds
	inconvenients: string;
	risks: string;
	fundComparison: string;
};

export type ResourcesState = {
	allDisclaimers: DisclaimerItem[];
	allUnderlyings: UnderlyingItem[];
};

const defaultState: ResourcesState = {
	allDisclaimers: [],
	allUnderlyings: [],
};

const ResourcesSlice = createSlice({
	initialState: defaultState,
	name: "resourcesAdministration",
	reducers: {
		loadDisclaimers: (_) => { },
		loadDisclaimersSuccess: (state, action: PayloadAction<{ disclaimers: any[] }>) => {
			state.allDisclaimers = action.payload.disclaimers;
		},

		updateDisclaimer: (
			_state,
			_action: PayloadAction<{ disclaimer: DisclaimerItem; success: () => void; error: (error: unknown) => void }>,
		) => { },

		loadUnderlyings: (_) => { },
		loadUnderlyingsSuccess: (state, action: PayloadAction<{ underlyings: any[] }>) => {
			state.allUnderlyings = action.payload.underlyings;
		},
		updateUnderlying: (
			_state,
			_action: PayloadAction<{ underlying: UnderlyingItem; success: () => void; error: (error: unknown) => void }>,
		) => { },
		deleteUnderlying: (
			_state,
			_action: PayloadAction<{ ticker: string; success: () => void; error: (error: unknown) => void }>,
		) => { },
	},
});

export default ResourcesSlice;
