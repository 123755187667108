import { Periodicity, DefaultColorPreset, ProductClient, commissionTypeItems } from "Domain/report";
import { TauxFixeTransversalConfiguration } from "Domain/report.tauxfixe";
import { TauxFixeTransversalConfigurationDto } from "Domain/reportDto.tauxfixe";
import { toDateOnly, toJSDate } from "Utilities/date";
import { computeDuration } from "Mappers/Tarn/tarnTransversalConfigurationMapper";
import { colorPresetMapper } from "Mappers/colorPresetMapper";
import { DurationInfoMappers } from "Mappers/durationInfoMappers";
import { TauxFixeScheduleLineMappers } from "./tauxFixeScheduleLineMappers";

function mapEntityToDto(entity: TauxFixeTransversalConfiguration): TauxFixeTransversalConfigurationDto {
	const dto: TauxFixeTransversalConfigurationDto = {
		productDuration: DurationInfoMappers.mapEntityToDto(entity.productDuration),
		productName: entity.productName,
		productPeriodicity: { period: Periodicity.Years, duration: (entity.maturityDate!.getFullYear() - entity.issueDate!.getFullYear()) },
		productGuarantor: entity.productGuarantor ?? "",
		productCotation: entity.productCotation,
		calculationAgent: entity.calculationAgent,
		productIssuer: entity.productIssuer,
		productCurrency: entity.productCurrency,
		minimumInvestment: entity.minimumInvestment,
		isin: entity.isin,
		mtmPublication: entity.mtmPublication,
		productLaw: entity.productLaw,
		hasMarketingPeriod: entity.marketingPeriod?.startDate !== undefined && entity.marketingPeriod?.endDate !== undefined,
		marketingStartDate: entity.marketingPeriod?.startDate ? toDateOnly(entity.marketingPeriod?.startDate) : undefined,
		marketingEndDate: entity.marketingPeriod?.endDate ? toDateOnly(entity.marketingPeriod?.endDate) : undefined,
		maturityDate: toDateOnly(entity.maturityDate),
		issueDate: toDateOnly(entity.issueDate),
		rateCurrency: entity.productCurrency,
		bidOffer: entity.bidOffer,
		colorPreset: colorPresetMapper.mapEntityToDto(entity.colorPreset),
		scheduleLines: entity.scheduleLines.map(TauxFixeScheduleLineMappers.mapEntityToDto),
		doubleValuation: entity.doubleValuation,
		fixedCoupon: entity.fixedCoupon,
		productEligibility: entity.productEligibility,
		pictureId: entity.pictureId,
		commission: entity.commission,
		commissionType: entity.commissionType ?? commissionTypeItems.distribués.key,
		feesPercent: entity.feesPercent,
		issuePrice: entity.issuePrice!,
		priceRate: entity.priceRate!,
		productDenomination: entity.productDenomination!,
		ftDate: toDateOnly(entity.ftDate),
		observationPeriodicity: entity.observationPeriodicity,
		client: entity.client,
		isCallable: entity.isCallable,
		callableStartPeriod: entity.callableStartPeriod,
		isInfine: entity.isInfine,
		callableNotificationPeriod: entity.callableNotificationPeriod
	};

	return dto;
}


function mapDtoToEntity(dto: TauxFixeTransversalConfigurationDto): TauxFixeTransversalConfiguration {
	const maturityDate = toJSDate(dto.maturityDate)!;
	const issueDate = toJSDate(dto.issueDate)!;
	const entity: TauxFixeTransversalConfiguration = {
		productName: dto.productName,
		productGuarantor: dto.productGuarantor,
		productCotation: dto.productCotation,
		calculationAgent: dto.calculationAgent,
		productIssuer: dto.productIssuer,
		productCurrency: dto.productCurrency,
		minimumInvestment: dto.minimumInvestment,
		isin: dto.isin,
		mtmPublication: dto.mtmPublication,
		productLaw: dto.productLaw,
		marketingPeriod: dto.hasMarketingPeriod
			? {
				startDate: toJSDate(dto.marketingStartDate, new Date()),
				endDate: toJSDate(dto.marketingEndDate, new Date()),
			}
			: undefined,
		maturityDate: maturityDate,
		issueDate: issueDate,
		productDuration: DurationInfoMappers.mapDtoToEntity(dto.productDuration) ?? computeDuration(issueDate, maturityDate),
		bidOffer: dto.bidOffer,
		colorPreset: colorPresetMapper.mapDtoToEntity(dto.colorPreset ?? DefaultColorPreset),
		scheduleLines: dto.scheduleLines.map(TauxFixeScheduleLineMappers.mapDtoToEntity),
		doubleValuation: dto.doubleValuation,
		fixedCoupon: dto.fixedCoupon,
		productEligibility: dto.productEligibility,
		pictureId: dto.pictureId,
		commission: dto.commission,
		commissionType: dto.commissionType,
		feesPercent: dto.feesPercent,
		issuePrice: dto.issuePrice,
		priceRate: dto.priceRate,
		productDenomination: dto.productDenomination,
		ftDate: dto.ftDate ? toJSDate(dto.ftDate) : undefined,
		observationPeriodicity: dto.observationPeriodicity as Periodicity,
		client: dto.client as ProductClient,
		isCallable: dto.isCallable,
		callableStartPeriod: dto.callableStartPeriod,
		isInfine: !!dto.isInfine,
		callableNotificationPeriod: dto.callableNotificationPeriod
	};

	return entity;
}

export const TauxFixeTransversalConfigurationMapper = {
	mapDtoToEntity,
	mapEntityToDto,
};
