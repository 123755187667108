import { productCurrencyItems, productLawItems, productEligibilityItems, periodicityItems, productClientItems, commissionTypeItems } from "Domain/report";
import { TarnReportConfiguration, TarnTransversalConfiguration, paymentDelayItems, couponDisplayModeItems } from "Domain/report.tarn";
import { FieldConfigurationFromModel } from "components/Shared/FormControls/FieldConfiguration";

export function getTarnFormFieldConfiguration(): FieldConfigurationFromModel<TarnReportConfiguration> {
	const transversalConfig: FieldConfigurationFromModel<TarnTransversalConfiguration> = {
		productName: { type: 'Text', displayLabel: 'Product Name *' },
		productCotation: { type: 'Text', displayLabel: 'Cotation *' },
		calculationAgent: { type: 'Text', displayLabel: 'Calculation *' },
		productIssuer: { type: 'Text', displayLabel: 'Issuer *' },
		productGuarantor: { type: 'Text', displayLabel: 'Guarantor *' },
		productCurrency: { type: 'List', displayLabel: 'Currency *', datasource: productCurrencyItems },
		minimumInvestment: { type: 'Number', displayLabel: "Minimum Investment *" },
		isin: { type: 'Text', displayLabel: 'ISIN Code (Optional)' },
		mtmPublication: { type: 'Text', displayLabel: 'Publication Of Liquidative Value *', multiline: false },
		productLaw: { type: 'List', displayLabel: 'Product Law *', datasource: productLawItems },
		hasLeverage: { type: 'Checkbox', displayLabel: null, valueLabel: 'Is there a leverage feature ?' },
		leverageAmount: { type: 'Percent', displayLabel: 'Leverage Amount (optional)' },
		marketingPeriod: { type: 'DateRange', displayLabel: 'Marketing Dates (optional)' },
		maturityDate: { type: 'Date', displayLabel: 'Maturity Date *' },
		issueDate: { type: 'Date', displayLabel: 'Issue Date *' },
		productDuration: { type: "Duration", displayLabel: "Product Duration *" },
		rateCurrency: { type: 'List', displayLabel: 'Currency *', datasource: productCurrencyItems },
		bidOffer: { type: 'Percent', displayLabel: 'Bid/Offer Spread *' },
		capitalPaymentBusinessDays: {
			type: 'List',
			displayLabel: 'Capital Payment Occurs (optional)',
			description: 'Business Days after the corresponding observation date',
			datasource: paymentDelayItems,
		},
		firstUnderlying: 'First Underlying *',
		secondUnderlying: 'Second Underlying *',
		autocallBarrier: {
			type: 'Percent',
			displayLabel: 'Total Coupons *',
		},
		colorPreset: 'Preset *',
		scheduleLines: 'Schedule Lines',
		doubleValuation: { type: 'Text', displayLabel: 'Double Valuation (optional)' },
		fixedCoupon: {
			type: 'Percent',
			displayLabel: 'Fixed Coupons *',
		},
		fixedCouponPeriods: {
			type: 'Number',
			displayLabel: 'Number Of Fixed Periods *',
		},
		totalPeriods: {
			type: 'Number',
			displayLabel: 'Total Number Of Periods *',
		},
		productEligibility: { type: 'List', displayLabel: 'Product Eligibility *', datasource: productEligibilityItems },
		commission: { type: 'Percent', displayLabel: 'Commission *' },
		commissionType: { type: "List", displayLabel: "Commission Type *", datasource: commissionTypeItems },
		feesPercent: { type: 'Percent', displayLabel: 'Fees *' },
		issuePrice: { type: 'Percent', displayLabel: 'Issue Price *' },
		productDenomination: { type: 'Number', displayLabel: 'Nominal Value *' },
		priceRate: { type: 'Percent', displayLabel: 'Price Rate *' },
		observationPeriodicity: { type: 'List', displayLabel: 'Observation Periodicity *', datasource: periodicityItems },
		client: { type: 'List', displayLabel: 'Client *', datasource: productClientItems },
		ftDate: { type: 'Date', displayLabel: 'FT Date (optional)' },
		hasZc: { type: 'Checkbox', valueLabel: 'Zero Coupon', displayLabel: null },
		bonusCoupon: { type: 'Percent', displayLabel: 'Bonus Coupon' },
		bonusPeriod: { type: 'DateRange', displayLabel: 'Coupon Bonus Period' },
		couponDisplayMode: { type: 'ButtonList', displayLabel: 'Show Coupons As', datasource: couponDisplayModeItems }
	};

	const config: FieldConfigurationFromModel<TarnReportConfiguration> = {
		transversalConfiguration: transversalConfig,
	};
	return config;
}
