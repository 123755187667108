const redirect_uri = `${window.location.protocol}//${window.location.host}/silent_renew.html`;

export const SGConnectConfig = {
	applicationName: "Neo.Tools.BrochureGenerator",
	nonProdClientId: "496d57a2-bb96-4a45-9965-af713e22e4d3",
	prodClientId: "b8e21668-326b-49e6-b80b-29379ae682c9",
	scopes: ["openid", "profile", "mail", "api.manage-contacts.v1", "api.brochure-generator.v1"],
	envConfig: {
		LOCAL: {
			apiUrl: "https://localhost.fr.world.socgen:54259/brochuregenerator/v1/",
			sgConnectConfig: {
				acr_values: "L1",
				redirect_uri,
			},
		},
		DEV: {
			apiUrl: "https://brochuregenerator-api-dev.sgmarkets.com/brochuregenerator/v1/",
			sgConnectConfig: {
				acr_values: "L1",
				redirect_uri,
			},
		},
		UAT: {
			apiUrl: "https://brochuregenerator-api-uat.sgmarkets.com/brochuregenerator/v1/",
			sgConnectConfig: {
				acr_values: "L1",
				redirect_uri,
			},
		},
		PRD: {
			apiUrl: "https://brochuregenerator-api.sgmarkets.com/brochuregenerator/v1/",
			sgConnectConfig: {
				acr_values: "L1",
				redirect_uri,
			},
		},
	},
};
