import { ProductCurrency, ProductLaw, DefaultColorPreset, Periodicity, commissionTypeItems } from "Domain/report";
import { TauxFixeReportConfiguration } from "Domain/report.tauxfixe";

export function createDefaultTauxFixeReport(): TauxFixeReportConfiguration {
	const reportConfig: TauxFixeReportConfiguration = {
		transversalConfiguration: {
			productName: '',
			productGuarantor: '',
			productCotation: 'Bourse de Luxembourg',
			productIssuer: 'Société Générale',
			productCurrency: ProductCurrency.EUR,
			productDenomination: 1_000,
			minimumInvestment: 1_000,
			issuePrice: 1,
			priceRate: 0,
			productLaw: ProductLaw.French,
			calculationAgent: 'Société Générale',
			isin: '',
			mtmPublication: '',
			bidOffer: 0.01,
			colorPreset: DefaultColorPreset,
			pictureId: '9f48d53a-d08c-4937-8218-fa9566ea1be6',
			scheduleLines: [],
			commission: 0.01,
			commissionType: commissionTypeItems.distribués.key,
			feesPercent: 0.01,
			observationPeriodicity: Periodicity.Years,
			isCallable: false,
			isInfine: false,
			callableNotificationPeriod: 10,
			productDuration: {
				years: 0,
				months: 0,
				days: 0,
			},
		},
	};
	return reportConfig;
}
